html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  background-color: #282828;
  color: #F6F8F7;
}

a {
  color: #282828;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

* {
  box-sizing: border-box;
}